<template>
  <div class="lean-improvement-changeover-wrapper pt-2 pb-3" v-if="game">
    <b-field position="is-centered" grouped class="mb-2">
      <b-select
        placeholder="Select a changeover duration"
        v-model="allFaciCHangeovers"
      >
        <option
          v-for="milli in changeoverTimesAvail"
          :value="milli"
          :key="milli"
        >
          {{ milli / 1000 }} sec
        </option>
      </b-select>
      <p class="control">
        <b-button
          @click="reduceChangeover()"
          type="is-info"
          rounded
          :disabled="!allFaciCHangeovers"
          >Apply to all stations</b-button
        >
      </p>
    </b-field>
    <div
      class="is-divider divider-text-darker"
      data-content="Or customize for each station :"
    ></div>
    <div class="columns is-centered is-multiline">
      <b-field
        class="column is-3 has-text-centered"
        v-for="faci in facis"
        :key="faci.id"
        position="is-centered"
      >
        <template slot="label"> {{ faci.name }}: </template>
        <b-field
          v-if="
            faci.params &&
              (faci.params.changeoverDuration === 0 ||
                faci.params.changeoverDuration)
          "
        >
          <b-select
            placeholder="Select a duration"
            v-model="faci.params.changeoverDuration"
          >
            <option
              v-for="milli in changeoverTimesAvail"
              :value="milli"
              :key="milli"
            >
              {{ milli / 1000 }} sec
            </option>
          </b-select>
        </b-field>
        <em
          class="has-text-grey"
          v-if="
            !faci.params ||
              (faci.params.changeoverDuration !== 0 &&
                !faci.params.changeoverDuration)
          "
        >
          No tool change in this station
        </em>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeanImprovementChangeover",
  props: {
    game: Object
  },
  computed: {
    facis() {
      return Object.values(this.newFacilities);
    }
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      this.readyWatch = false;
      this.newFacilities = JSON.parse(JSON.stringify(this.game.facilities));
      setTimeout(() => {
        this.readyWatch = true;
      }, 5);
    },
    reduceChangeover() {
      if (this.allFaciCHangeovers) {
        this.facis.forEach(fac => {
          if (fac.params && fac.params.changeoverDuration) {
            fac.params.changeoverDuration = this.allFaciCHangeovers;
          }
        });
      }
    }
  },
  watch: {
    newFacilities: {
      handler: function() {
        if (this.readyWatch) {
          this.$emit("update", { type: "changeover", obj: this.newFacilities });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      readyWatch: false,
      newFacilities: {},
      allFaciCHangeovers: null,
      changeoverTimesAvail: [100, 500, 1000, 2000, 3000, 5000]
    };
  }
};
</script>

<style lang="scss">
.lean-improvement-changeover-wrapper {
}
</style>
