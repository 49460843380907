var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.game)?_c('div',{staticClass:"lean-improvement-changeover-wrapper pt-2 pb-3"},[_c('b-field',{staticClass:"mb-2",attrs:{"position":"is-centered","grouped":""}},[_c('b-select',{attrs:{"placeholder":"Select a changeover duration"},model:{value:(_vm.allFaciCHangeovers),callback:function ($$v) {_vm.allFaciCHangeovers=$$v},expression:"allFaciCHangeovers"}},_vm._l((_vm.changeoverTimesAvail),function(milli){return _c('option',{key:milli,domProps:{"value":milli}},[_vm._v(" "+_vm._s(milli / 1000)+" sec ")])}),0),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-info","rounded":"","disabled":!_vm.allFaciCHangeovers},on:{"click":function($event){return _vm.reduceChangeover()}}},[_vm._v("Apply to all stations")])],1)],1),_c('div',{staticClass:"is-divider divider-text-darker",attrs:{"data-content":"Or customize for each station :"}}),_c('div',{staticClass:"columns is-centered is-multiline"},_vm._l((_vm.facis),function(faci){return _c('b-field',{key:faci.id,staticClass:"column is-3 has-text-centered",attrs:{"position":"is-centered"}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(faci.name)+": ")]),(
          faci.params &&
            (faci.params.changeoverDuration === 0 ||
              faci.params.changeoverDuration)
        )?_c('b-field',[_c('b-select',{attrs:{"placeholder":"Select a duration"},model:{value:(faci.params.changeoverDuration),callback:function ($$v) {_vm.$set(faci.params, "changeoverDuration", $$v)},expression:"faci.params.changeoverDuration"}},_vm._l((_vm.changeoverTimesAvail),function(milli){return _c('option',{key:milli,domProps:{"value":milli}},[_vm._v(" "+_vm._s(milli / 1000)+" sec ")])}),0)],1):_vm._e(),(
          !faci.params ||
            (faci.params.changeoverDuration !== 0 &&
              !faci.params.changeoverDuration)
        )?_c('em',{staticClass:"has-text-grey"},[_vm._v(" No tool change in this station ")]):_vm._e()],2)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }